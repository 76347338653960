<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <div v-if="!this.currentPackage.occupation_types.length>0" class="mt-4">
          <b-card  title="Étape 3" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">

            <b-card-text>
             Pour chaque type d'occupation offert dans ce forfait, veuillez ajouter le prix par type de voyageur.
            </b-card-text>

            <b-button variant="primary"  @click="showPackageRulesModal">Ajouter</b-button>
          </b-card>
        </div>
        <b-card v-else  header-tag="header" class="mt-2" >
          <template #header>
              <h3> Prix du forfait </h3>
              <b-button variant="outline-primary" @click="showPackageRulesModal">Modifier</b-button>
          </template>
          <b-row>

            <b-col cols="6" v-for="occupation in getpricing" :key="occupation" >
              <b-row class="mb-2">
                <b-col>
                  <h4 class="text-primary text-capitalize"> {{occupation.type}} </h4>
                </b-col>
              </b-row>
              <b-row>

              </b-row>
              <div class="mt-2">
                  <b-list-group>
                      <b-list-group-item class="dark-line-item" >
                        Prix par adulte (taxes incluses)
                      </b-list-group-item>
                      <b-list-group-item v-for="pricing,index in getSpecificCategory(occupation, 'ADULT')" :key="index">
                        Adulte #{{index+1}}
                        <span  style="float:right">${{pricing.price}}</span>
                      </b-list-group-item>
                       <b-list-group-item class="dark-line-item" v-if="currentPackage.accept_children">
                        Prix par enfant (taxes incluses)
                      </b-list-group-item>
                      <div v-for="children_category,children_cat_index in occupation.supported_children_types" :key="children_cat_index" >
                        <b-list-group-item v-for="pricing,child_index in getSpecificCategory(occupation,children_category.toUpperCase())" :key="child_index">
                        {{ TravelerCategoryDenomination(children_category) }} #{{child_index+1}}
                        <span  style="float:right">${{pricing.price}}</span>
                      </b-list-group-item>
                      </div>
                  </b-list-group>
              </div>
            </b-col>

          </b-row>
          <div class="text-right">
            <b-button variant="primary" class="ml-1" @click="$emit('confirm-package')">Confirmer</b-button>
          </div>
        </b-card>
        <group-package-prices-modal ref='package-prices-modal' :action-type="actionType" :current-package="currentPackage" @submitPackagePricing="submitPackagePricing" />




      </b-col>
      <b-col
        lg="6"
        md="6"
      >
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import GroupPackagePricesModal from '@/views/groups/group/GroupPackages/PackageModals/GroupPackagePricesModal'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BListGroup,
    BListGroupItem,
    GroupPackagePricesModal

  },
  props: {
    currentPackage: {
      type: Object
    },
    actionType: {
      type: String
    }
  },
  data() {
    return {
      hasPricingInfo: false,
      currentGroup: {},
    }
  },
  methods: {
    showPackageRulesModal() {
      this.$refs['package-prices-modal'].$children[0].show()
    },
    showEditBasicInfoModal() {
      this.$refs['edit-basic-info-modal'].$children[0].show()
    },
    submitPackagePricing() {
      this.hasPricingInfo = true;
      this.$emit('submitPackagePricing', this.currentPackage)
    },
    displayCreationConfirmation(error) {
      let title = 'Groupe sauvegardé comme brouillon';
      let variant = 'success'

      if (error) {
        title = error.data.message
        variant = 'danger'
      }


      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'EditIcon',
          variant,
        },
      })
    },
    TravelerCategoryDenomination(type)
    {
      const types = {
        ADULT:"Adulte",
        BABY_TWO_YEARS:"Bébé 2 ans",
        BABY_TWO_YEARS_OR_LESS:"Bébé moins de 2 ans",
        CHILD_TWO_TO_TWELVE:"Enfant 2 à 12 ans",
        CHILD_THREE_TO_ELEVEN: "Enfant 3 à 11 ans",
        CHILD_THREE_TO_TWELVE:"Enfant 3 à 12 ans",
        CHILD_TWO_TO_FIVE:"Enfant 2 à 5 ans",
        CHILD_FIVE_TO_TWELVE:"Enfant 5 à 12 ans",
        TEEN_THIRTEEN_TO_SEVENTEEN:"Ado 13 à 17 ans",
      }

      return types[type];

    },
    getSpecificCategory(occupation, category) {
      let result = []
      occupation.occupation_type_pricing.forEach(pricing => {
        if (pricing.type == category) {
          result.push(pricing)
        }
      })
      return result
    },

  },
  computed: {
    getpricing() {
      try {
        return this.currentPackage?.occupation_types
      } catch {
        return []
      }
    }
  },
}
</script>

<style>

  [dir] .list-group-item {
      background-color: #fff;
      border: 1px solid rgba(34, 41, 47, 0.125);
      padding: 5px;
  }

  .dark-line-item, .dark-line-item:hover {
    background-color: #d8d6de !important;
  }

  .children-pricing-border-top {
    border-top: 1px solid #4b4b4b;
  }
</style>
