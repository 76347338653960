export default class GroupDTO {

  constructor() {}

  formatGroupFromAPIObject(group_from_api) {
    try {
      let groupDTO = {
        id: group_from_api.id,
        name: group_from_api.name,
        link: group_from_api.link,
        type: group_from_api.type,
        description: group_from_api.description,
        deposit_amount: group_from_api.deposit_amount,
        difficulty:group_from_api.difficulty,
        exclusions:group_from_api.exclusions,
        inclusions:group_from_api.inclusions,
        extra_information: group_from_api.extra_information,
        guide:group_from_api.guide,
        requirements: group_from_api.requirements,
        booking_limit: group_from_api.booking_limit,
        departure_date: group_from_api.departure_date,
        deposit_limit: group_from_api.deposit_limit,
        payment_limit: group_from_api.payment_limit,
        return_date: group_from_api.return_date,
        images: group_from_api.images,
        status: group_from_api.status,
        sale_status: group_from_api.sale_status,
        access_status: group_from_api.access_status,
        public_access_token: group_from_api.public_access_token,
        allowed_agencies: group_from_api.allowed_agencies,
      }
      return groupDTO;

    } catch (error) {
      console.error(error)

    }
  }

  formatDateFromArray(dateArray) {
    if (dateArray && dateArray.length == 3) {
      //Add O if single Digit Month

      if (dateArray[1].toString().length == 1) {
        dateArray[1] = "0" + dateArray[1].toString();
      }
      //Add O if single Digit Day
      if (dateArray[2].toString().length == 1) {
        dateArray[2] = "0" + dateArray[2].toString();
      }

      return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`
    } else {
      return null;
    }
  }

}
