<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2">
            Information du forfait
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12">
          <validation-provider
            #default="{ errors }"
            name="Nom du forfait"
            rules="required"
          >
            <b-form-group
              label="Nom du forfait"
              label-for="transport-options"
            >
              <b-form-input
                id="`option-name-%{option.index}`"
                :state="errors.length > 0 ? false:null"
                v-model="option.name"
                placeholder="Escapade à Paris"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6" sm="12" v-if="pcVoyageEnable">
          <validation-provider
            #default="{ errors }"
            name="Numéro de référence"

          >
            <b-form-group
              label="Numéro du référence"
              label-for="reference-number"
            >
              <b-form-input
                id="`reference-number`"
                :state="errors.length > 0 ? false:null"
                v-model="option.reference_number"
                placeholder=""
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <!--
        <b-col md="6">
          <b-form-group
            label="Description"
            label-for="description-froup"
          >
          <b-form-textarea
            rows="10"
            size="sm"
            v-model="option.description"
          >
          </b-form-textarea>
          </b-form-group>
        </b-col>
        -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Image du forfait"
            :rules="currentPackage.image_url===undefined ? 'required' : ''"
          >
            <b-form-group
              label="Image du forfait"
              label-for="description-froup"

            >

              <b-form-file
                ref="file-upload"
                v-model="images.file1"
                placeholder="Veuillez sélectionner une image..."
                :state="errors.length > 0 ? false:null"
                drop-placeholder="Déposer l'image ici..."
                browse-text="Parcourir"
                @change="uploadImageToPackage"
              ></b-form-file>
              <small class="form-text text-muted">Poids maximal de l'image: 5 Mo</small>
              <!--
                <b-img class="mt-1" :src="option.image_url" fluid ></b-img>
                <div class="mt-1">Selected file: {{ images.file1 ? images.file1.name : '' }}</div>
              -->

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-img class="mt-1" :src="option.image_url" fluid></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2">
            Paramètres du forfait
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Option de transport associé au forfait"
            rules="required"
          >
            <b-form-group
              label="Option de transport associé au forfait"
              label-for="transport-options"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                :options="transportOptions"
                v-model="option.transportation"
                label="name"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

      </b-row>
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Type d'occupation offert dans ce forfait"
            rules="required"
          >
            <b-form-group
              label="Type d'occupation offert dans ce forfait"
              label-for="transport-options"
              :state="errors.length > 0 ? false:null"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="option.occupation_types"
                  name="flavour-2"
                >
                  <b-form-checkbox
                    :value="{type:'single'}"
                  >
                    Simple
                  </b-form-checkbox>
                  <b-form-checkbox
                    :value="{type:'double'}"
                  >
                    Double
                  </b-form-checkbox>
                  <b-form-checkbox
                    :value="{type:'triple'}"
                  >
                    Triple
                  </b-form-checkbox>
                  <b-form-checkbox
                    :value="{type:'quadruple'}"
                  >
                    Quadruple
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <!-- primary -->
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Enfants acceptés dans le groupe"
            rules="required"
          >
            <b-form-group
              label="Enfants acceptés dans le groupe"
              label-for="Enfants acceptés dans le groupe"
              :state="errors.length > 0 ? false:null"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  :name="`are_children_accepted-radios`"
                  value="true"
                  v-model='option.are_children_accepted'
                >
                  Oui
                </b-form-radio>
                <b-form-radio
                  :name="`are_children_accepted-radios`"
                  v-model='option.are_children_accepted'

                  value="false"
                >
                  Non
                </b-form-radio>
              </div>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
              v-if="publicUrlEnabled"
              label="Affichage du forfait"
              label-for="Affichage du forfait"
            >
            <div class="mt-1">
              <b-form-checkbox
                v-model="option.is_public"
                class="mr-0 mt-50"
                name="is-rtl"
                switch
                inline
              >
                Visible sur la page du groupe pour les clients externes
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mt-2 mb-2">
            Hébergement associé au forfait
          </h1>
        </b-col>
      </b-row>
      <b-row v-for="(value, index) in option.room_ids" :key='index'>
        <b-col md="5">
          <validation-provider
            #default="{ errors }"
            name="Hébergement associé"
            rules="required"
            :vid="'Hébergement associé'+index"
          >
            <b-form-group
              label="Hébergement associé"
              label-for="accommodation-options"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                :id="'Hébergement associé'+index"
                v-model="option.room_ids[index].accommodation"
                :options="accommodationOptions"
                label="name"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!--
        <b-col md="3">
          <b-form-group
            label="Date check-in"
            label-for="transport-options"
          >
            <b-form-datepicker
              locale="fr"
              class="mb-1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="option.room_ids[index].checkin"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Date check-out"
            label-for="transport-options"
          >
            <b-form-datepicker
              locale="fr"
              class="mb-1"
              v-model="option.room_ids[index].checkout"

              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-form-group>
        </b-col >
        -->

        <b-col md="1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-25"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="addAccommodationToPackage(option)"
          >
            + Ajouter un hébergement associé au forfait
          </b-button>
          <validation-provider
            #default="{ errors }"
            name="hébergement associé"
            rules="required"
          >
            <input type="hidden" v-model="option.room_ids" :state="errors.length > 0 ? false:null">
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              <feather-icon
                icon="AlertCircleIcon"
                class="text-danger align-middle mr-25"
              />
              Il faut choisir un hébergement
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import {required} from '@validations'
  import {useGroupApi} from "@/modules/group/composables/use-group-api";
  import {reactive} from "@vue/composition-api";
  import Vue from "vue";
  import {
    BButton,
    BCardText, BCol,
    BForm, BFormCheckbox, BFormCheckboxGroup,
    BFormDatepicker, BFormFile,
    BFormGroup,
    BFormInput, BFormInvalidFeedback, BFormRadio, BFormTextarea,
    BImg, BInputGroup, BInputGroupPrepend,
    BModal,
    BRow, BTab, BTabs, VBModal
  } from "bootstrap-vue";
  import BCardCode from "@core/components/b-card-code";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import Ripple from "vue-ripple-directive";
  import {useApplicationContext} from "@/shared/composables/use-application-context";
  import {useToastNotification} from "@/shared/composables/use-toast-notifications";
  import store from "@/store";
  import {
    AUTH_STORE_NAMESPACE,
  } from "@/modules/authnz/models/store";

  export default {
    components: {
      BImg,
      ValidationProvider,
      ValidationObserver,
      BCardCode,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BCardText,
      BFormGroup,
      vSelect,
      BFormDatepicker,
      BRow,
      BCol,
      BInputGroup,
      BFormCheckbox,
      BFormCheckboxGroup,
      BInputGroupPrepend,
      BFormTextarea,
      BTabs,
      BFormFile,
      BTab,
      BFormInvalidFeedback,
      ToastificationContent,
      BFormRadio,
    },
    name: "PackageInfoForm",
    directives: {
      Ripple,
    },
    props: {
      transportOptions: {
        type: Array
      },
      accommodationOptions: {
        type: Array
      },
      currentPackage: {
        type: Object
      },
      pcVoyageEnable: {
        type: Boolean
      }
    },
    setup(props) {
      const publicUrlEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.public_url_enable;
      const {uploadImage} = useGroupApi();
      const {displayErrorMessage} = useToastNotification();

      let images = reactive({})
      let option = reactive({
        name:props.currentPackage.name,
        image_url:props.currentPackage.image_url,
        is_public: props.currentPackage.is_public ?? true,
        reference_number: props.currentPackage?.reference_number || null,
        room_ids:[],
        occupation_types:[],
        transportation:props.currentPackage.transportation,
        are_children_accepted:null,
      })

      if (props.currentPackage.name !== undefined){
        option.name=props.currentPackage.name;
        option.image_url=props.currentPackage.image_url;

        if (props.currentPackage.reference_number !== undefined) {
          option.reference_number=props.currentPackage.reference_number;
        }

        if (props.currentPackage.rooms.length>0){
          props.currentPackage.rooms.forEach(element=>{
            option.room_ids.push({ accommodation: element.id, checkin: null, checkout: null });
          });
        }
        if (props.currentPackage.occupation_types.length>0){
          props.currentPackage.occupation_types.forEach(element=>{
            option.occupation_types.push({type:element.type.toLowerCase()});
          });
        }
        option.transportation=props.currentPackage.transportation;
        // option.are_children_accepted=!!props.currentPackage.occupation_types.find(element => element.supported_children_types.length > 0);
        option.are_children_accepted=props.currentPackage.accept_children
      }

      const uploadImageToPackage = async (event) => {
        let file = event.target.files[0];
        if (file) {
          let data = new FormData();
          data.append('name', file.name);
          data.append('type', 'image');
          data.append('file', event.target.files[0]);

          try {
            let response = await uploadImage(data)
            Vue.set(option, 'image_url', response.headers.location)
          } catch (e) {
            displayErrorMessage("Le téléversement de l'image a échoué.");
            images.file1 = null
            this.$refs['file-upload'].reset()
          }
        }
      }
      return {
        publicUrlEnabled,
        option ,
        images,
        uploadImageToPackage,
      }
    },
    data(){

    },
    methods:{
      addAccommodationToPackage(){
        this.option.room_ids.push({
          accommodation: null,
          checkin: null,
          checkout: null
        })
      },
      removeItem (deleteIndex) {
        this.option.room_ids.splice(deleteIndex, 1)
      },

    },
    watch: {
      currentPackage: function (newVal, oldVal) { // watch it
          if(newVal.name!==undefined){
            this.option.name=newVal.name;
            this.option.image_url=newVal.image_url;

            if (newVal.reference_number !== undefined) {
              option.reference_number = newVal.reference_number;
            }

            if (newVal.rooms.length>0){
              newVal.rooms.forEach(element=>{
                this.option.room_ids.push({ accommodation: element.id, checkin: null, checkout: null });
              });
            }

            if (newVal.occupation_types.length>0){
              newVal.occupation_types.forEach(element=>{
                this.option.occupation_types.push({type:element.type.toLowerCase()});
              });
            }

            this.option.transportation=newVal.transportation;
            // option.are_children_accepted=!!newVal.occupation_types.find(element => element.supported_children_types.length > 0);
            this.option.are_children_accepted=newVal.accept_children;
        }
    }
  },
  }
</script>

<style scoped>

</style>
