<template>
  <validation-observer ref="simpleRules">
  <b-form>
    <b-row>
      <b-col md="6">
        <h1 class="section-label mx-0 mb-2">
          Règles d'hébergement pour ce forfait
        </h1>
      </b-col>
      <b-col md="12">
        <b-tabs>
          <b-tab v-for="(occupation,index) in getOccupationType" :key="index">

            <template #title>
              <span>{{occupation.type}}</span>
            </template>

            <b-row>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  :vid="'Nombre d\'adultes minimum'+index"
                  name="Nombre d'adultes minimum"
                  rules="required|regex:^([1-6]+)$"

                >
                <b-form-group
                  label="Nombre d'adultes minimum"
                  label-for="transport-options"
                >
                  <b-form-input
                    :id="'Nombre d\'adultes minimum'+index"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nombre de voyageurs"
                    v-model="occupation.adult_min"
                  />

                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre de voyageurs maximum"
                  :vid="'Nombre de voyageurs maximum'+index"
                  rules="required|regex:^([1-6]+)$"
                >
                <b-form-group
                  label="Nombre de voyageurs maximum"
                  label-for="transport-options"
                >


                  <b-form-input
                    :id="'Nombre de voyageurs maximum'+index"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nombre de voyageurs"
                    v-model="occupation.travelers_max"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div v-if="currentPackage.accept_children">
              <b-row >
                <b-col md="12" >
                  <b-form-group
                    label="Les types de voyageurs (excluant adultes) offerts dans ce groupe "
                    label-for="transport-options"
                    class="mb-1"
                    :id="'child'+index"
                  >
                    <b-row>
                      <b-col v-for="(categories,category_index) in groupCreationConfig.childrenCategories" :key="category_index" md=3>
                        <b-form-checkbox-group

                          :options='categories'
                          name="flavour-2a"
                          stacked
                          class="mt-1"
                          v-model="occupation.supported_children_types"
                        >
                        </b-form-checkbox-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>

              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-form>
  </validation-observer>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import BCardCode from "@core/components/b-card-code";
    import {
      BButton,
      BCardText,
      BCol,
      BForm, BFormCheckbox, BFormCheckboxGroup,
      BFormDatepicker, BFormFile,
      BFormGroup,
      BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupPrepend,
      BModal,
      BRow, BTab, BTabs, VBModal
    } from "bootstrap-vue";
    import vSelect from "vue-select";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import Ripple from "vue-ripple-directive";
    import groupCreationConfig from "@core/data/groupCreationConfig";
    export default {
        name: "PackageRulesForm",
      components: {
        BCardCode,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BForm,
        BFormInput,
        BCardText,
        BFormGroup,
        vSelect,
        BFormDatepicker,
        BRow,
        BCol,
        BInputGroup,
        BFormCheckbox,
        BFormCheckboxGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BTabs,
        BFormFile,
        BTab,
        BFormInvalidFeedback,
        ToastificationContent,
      },
      directives: {
        Ripple,
      },
      props: {
        currentPackage: {
          type: Object
        }
      },
      data() {
        return {
          hasGuide: false,
          selected: 'USA',
          errorsTabs:[],
          option: ['USA', 'Canada', 'Maxico'],
          groupCreationConfig,
          pkg: {
            occupation_types:['single','double'],
            rules: {
              single: {},
              double: {}
            },
            are_children_accepted: 'true'
          },
          option_index:0,
          index:1
        }
      },
      methods: {
        submitPackageRules (event) {
          this.$emit('submitPackageRules', this.currentPackage)
        },
        removeItem(optionIndex, deleteIndex) {
          this.option[optionIndex].room_ids.splice(deleteIndex, 1)
        },
        occupationLabel(occupation) {
          let labels = {
            single: 'Simple',
            double: 'Double',
            triple: 'Triple',
            quadruple: 'Quadruple'
          }

          return labels[occupation];
        }
      },
      computed: {
        getOccupationType() {
          try {
            return this.currentPackage.occupation_types
          } catch {
            return []
          }
        }
      }
    }
</script>

<style scoped>

</style>
